//个人数据

import React from "react";

function PersonData() {
  return (
    <>
      <div className="unique-width">
          <table className="table table-striped-columns table-bordered table-lg text-center align-middle">
            <colgroup>
              <col className="col-2" />
              <col className="col-4" />
              <col className="col-2" />
              <col className="col-4" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">昵称</th>
                <td>阿牙</td>
                <th scope="row">Nick</th>
                <td>Ace Zane</td>
              </tr>
              <tr>
                <th scope="row">战队</th>
                <td>赛文搏击</td>
                <th scope="row">Team</th>
                <td>Seven Combat Academy</td>
              </tr>
              <tr>
                <th scope="row">身高</th>
                <td>1米76</td>
                <th scope="row">Height</th>
                <td>176cm</td>
              </tr>
              <tr>
                <th scope="row">体重</th>
                <td>58公斤</td>
                <th scope="row">Weight</th>
                <td>58kg</td>
              </tr>
              <tr>
                <th scope="row">年龄</th>
                <td>16</td>
                <th scope="row">Age</th>
                <td>16</td>
              </tr>
              <tr>
                <th scope="row">星座</th>
                <td>狮子座</td>
                <th scope="row">Zodiac</th>
                <td>Leo</td>
              </tr>
              <tr>
                <th scope="row">格言</th>
                <td>武艺合一</td>
                <th scope="row">Motto</th>
                <td>Where Combat Meets Art</td>
              </tr>
            </tbody>
          </table>
      </div>
    </>
  );
}

export default PersonData;
