//冠军数据

import React from "react";
import champion202401 from "../imagines/champion202401.jpg";

function Champions() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="row g-0">
              <div className="col-md-5">
                <img
                  src={champion202401}
                  className="img-fluid rounded-start"
                  alt="Champion"
                />
              </div>
              <div className="col-md-7">
                <div className="card-body">
                  <div className="mb-4">
                    <h4 className="card-title text-danger">冠军</h4>
                    <h5 className="card-text">昊江湖自由搏击争霸赛</h5>
                    <h6 className="card-text">
                      <span className="text-body-secondary">2024年1月</span>
                    </h6>
                  </div>
                  <div className="mt-4">
                    <h4 className="card-title text-danger">Champion</h4>
                    <h5 className="card-text">Hao Jianghu Freestyle Fighting Championship</h5>
                    <h6 className="card-text">
                      <span className="text-body-secondary">January 2024</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Champions;
