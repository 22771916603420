import React, { useState, useEffect } from "react";

const mottos = [
  {
    chinese: "天行健，君子以自强不息",
    english: "As heaven maintains vigor through movements, a gentleman should constantly strive for self-perfection"
  },
  {
    chinese: "春衣少年当酒歌，起舞四顾以笑和。",
    english: "Young men in spring attire sing with wine, dancing and laughing in all directions."
  },
  {
    chinese: "除却君身三尺雪，天下谁人配白衣。",
    english: "Besides you in your three feet of snow, who else in the world is worthy of wearing white?"
  },
  {
    chinese: "少年负壮气，奋烈自有时。",
    english: "The young bear vigorous spirit; their time for passionate endeavors will come."
  },
  {
    chinese: "陌上归来少年郎，满身兰麝扑人香。",
    english: "The young man returns from the street, his body full of fragrant musk."
  },
  {
    chinese: "季子正年少，匹马黑貂裘。",
    english: "The young noble in his prime, riding a horse in a black sable coat."
  },
  {
    chinese: "新丰美酒斗十千，咸阳游侠多少年。",
    english: "Fine wine from Xinfeng costs ten thousand, how many years have the knights of Xianyang roamed?"
  },
  {
    chinese: "俱怀逸兴状思飞，欲上青天揽明月。",
    english: "All harboring lofty thoughts as if to fly, wishing to ascend the blue sky and grasp the bright moon."
  },
  {
    chinese: "仰天大笑出门去，吾辈岂是蓬蒿人。",
    english: "Laughing heartily to the sky as I leave, how could we be mere common folk?"
  },
  {
    chinese: "青春须早为，岂能长少年。",
    english: "Youth must be seized early, how can one remain young forever?"
  },
  {
    chinese: "日月纷纷车走坂，少年意气何由挽。",
    english: "Days and months rush by like chariots down a slope, how can the spirit of youth be retained?"
  },
  {
    chinese: "词源倒流三峡水,笔阵独扫千人军。",
    english: "His words flow like the reversed waters of Three Gorges, his pen sweeps away an army of a thousand."
  },
  {
    chinese: "青山意气峥嵘，似为我归来妩媚生。",
    english: "The spirit of green mountains stands tall, as if becoming charming for my return."
  },
  {
    chinese: "横行青海夜带刀，西屠石堡取紫袍。",
    english: "Roaming Qinghai at night with a sword, conquering stone fortresses in the west for the purple robe."
  },
  {
    chinese: "春日游，杏花吹满头，陌上谁家年少，足风流。",
    english: "Spring outing, apricot blossoms blown all over the head, whose young man on the path is so dashing?"
  },
  {
    chinese: "少年恃险若乎地，独倚长剑凌清秋。",
    english: "The youth treats danger as level ground, alone he leans on his long sword in the clear autumn."
  },
  {
    chinese: "鲜衣怒马少年时，能堪那金贼南渡?",
    english: "In youth with fine clothes and spirited horses, how could we bear the Jin invaders crossing south?"
  },
  {
    chinese: "天生我材必有用，千金散尽还复来。",
    english: "Heaven-born talents must have their use, a thousand gold spent will come again."
  },
  {
    chinese: "看花东陌上，惊动洛阳人。",
    english: "Viewing flowers on the east path, startling the people of Luoyang."
  },
  {
    chinese: "白马饰金羁，连翩西北驰。",
    english: "White horse adorned with golden bridle, galloping swiftly to the northwest."
  },
  {
    chinese: "少年意气强不羁，虎胁插翼白日飞。",
    english: "The spirit of youth is strong and unrestrained, like a winged tiger soaring in broad daylight."
  },
  {
    chinese: "满堂花醉三千客，一剑霜寒十四州。",
    english: "A hall full of flower-drunk guests numbering three thousand, one frosty sword chills fourteen prefectures."
  },
  {
    chinese: "少年猎得平原兔，马后横捎意气归。",
    english: "The youth hunts a hare on the plain, returning with high spirits, the prey slung across his horse."
  },
  {
    chinese: "少年击剑更吹箫，剑气箫心一例消。",
    english: "The youth strikes with his sword and plays the flute, the sword's spirit and flute's heart both dissipate."
  },
  {
    chinese: "长安少年游侠客，夜上戍楼看太白。",
    english: "Young knights-errant of Chang'an, climbing the watchtower at night to view Venus."
  },
  {
    chinese: "少年驰射出幽并，高秋摇落重横行。",
    english: "The youth rides out from You and Bing to hunt, in late autumn he roams freely once more."
  },
  {
    chinese: "弓背霞明剑照霜，秋风走马出咸阳。",
    english: "Bow reflecting the sunset, sword gleaming with frost, riding out of Xianyang in the autumn wind."
  },
  {
    chinese: "游宴不知厌，杜陵狂少年。",
    english: "Never tiring of feasts and outings, the wild youth of Duling."
  },
  {
    chinese: "花时轻暖酒，春服薄装绵。",
    english: "Light warm wine in flower season, spring clothes thin and soft."
  },
  {
    chinese: "一身转战三千里，一剑曾当百万师。",
    english: "One man fighting across three thousand li, one sword once worth a million troops."
  },
  {
    chinese: "黄花盖野田，白马少年游。",
    english: "Yellow flowers cover the wild fields, a white horse carries the young man on his journey."
  },
  {
    chinese: "少年乘勇气，百战过乌孙。",
    english: "The youth, riding on courage, fights a hundred battles past Wusun."
  },
  {
    chinese: "平生一顾念，意气溢三军。",
    english: "One glance in a lifetime, the spirit overflows three armies."
  },
  {
    chinese: "少年意气强不羁，虎胁插翼白日飞。",
    english: "The spirit of youth is strong and unrestrained, like a winged tiger soaring in broad daylight."
  },
  {
    chinese: "一身能擘两雕弧，虏骑千重只似无。",
    english: "One man can draw two carved bows, a thousand enemy riders seem as nothing."
  },
  {
    chinese: "少年斗猛气，怒发为君征。",
    english: "The youth's fierce spirit contends, with rage he campaigns for his lord."
  },
  {
    chinese: "犀渠玉剑良家子，白马金羁侠少年。",
    english: "Rhinoceros horn hilt and jade sword mark the noble youth, white horse with golden bridle, the young knight-errant."
  },
  {
    chinese: "一卷旌收千骑虏，万全身出百重围。",
    english: "One banner captures a thousand enemy riders, emerging unscathed from a hundred encirclements."
  },
  {
    chinese: "五羊城在蜃楼边，墨绶垂腰正少年。",
    english: "The City of Five Rams stands by the mirage, the young official with black ribbon at his waist."
  },
  {
    chinese: "当时年少春衫薄，骑马倚斜桥，满楼红袖招。",
    english: "In those days, young in thin spring robes. Riding a horse by the slanting bridge, red sleeves beckon from every floor."
  },
  {
    chinese: "冲天香阵透长安，满城尽带黄金甲。",
    english: "A heavenly fragrance permeates Chang'an, the whole city dons golden armor."
  },
  {
    chinese: "壮岁旌旗拥万夫，锦襜突骑渡江初。",
    english: "In prime years, banners lead ten thousand men, brocade-clad cavalry first to cross the river."
  },
  {
    chinese: "春风得意马蹄疾，一日看尽长安花。",
    english: "Riding on spring breeze and success, horse hooves fly, seeing all Chang'an's flowers in a day."
  },
  {
    chinese: "君不见长松卧壑因风霜，时来屹立扶是堂。",
    english: "Do you not see the tall pine lying in the valley due to wind and frost? When its time comes, it will stand tall to support the hall."
  },
  {
    chinese: "休对故人思故国，且将新火试新茶。诗酒趁年华。",
    english: "Don't think of the old country with old friends, instead use new fire to brew new tea. Seize the day with poetry and wine."
  },
  {
    chinese: "相逢意气为君饮，系马高楼垂柳边。",
    english: "Meeting with high spirits, I drink to you, tying my horse by the high tower near hanging willows."
  },
  {
    chinese: "须知少时凌云志，曾许人间第一流。",
    english: "You must know the youthful ambition to soar above the clouds, once promised to be the best in the world."
  },
  {
    chinese: "鲜衣怒马少年时，能堪那金贼南渡？",
    english: "In youth with fine clothes and spirited horses, how could we bear the Jin invaders crossing south?"
  },
  {
    chinese: "画凌烟，上甘泉。自古功名属少年。",
    english: "Painting the Lingyan Pavilion, ascending Mount Ganquan. Since ancient times, fame and glory belong to the young."
  },
  {
    chinese: "莫等闲，白了少年头，空悲切。",
    english: "Don't idle away, letting youthful hair turn white, leaving only vain regrets."
  },
  {
    chinese: "力拔山兮气盖世，时不利兮骓不逝。",
    english: "Strength to uproot mountains, spirit to cover the world, but times are unfavorable and the steed won't advance."
  },
  {
    chinese: "骓不逝兮可奈何，虞兮虞兮奈若何！",
    english: "The steed won't advance, what can be done? Oh, the sorrow, the sorrow, what can be done!"
  },
  {
    chinese: "豪情还剩文章在，剑气犹存横海东。",
    english: "Grand ambitions remain in writings, the spirit of the sword still exists east of the sea."
  },
  {
    chinese: "千秋万岁名，寂寞身后事。",
    english: "A name lasting for ages, a lonely matter after death."
  },
  {
    chinese: "仰不愧于天，俯不怍于人。",
    english: "Looking up, not ashamed before Heaven; looking down, not ashamed before men."
  },
  {
    chinese: "壮志饥餐胡虏肉，笑谈渴饮匈奴血。",
    english: "With grand ambitions, hunger is satisfied by eating the flesh of invaders, thirst quenched by drinking the blood of Huns."
  },
  {
    chinese: "怒发冲冠，凭栏处、潇潇雨歇。",
    english: "Hair bristling in anger pushes up my hat, I lean on the railing as the pattering rain stops."
  },
  {
    chinese: "抬望眼、仰天长啸，壮怀激烈。",
    english: "Raising my eyes, I look up to the sky and let out a long roar, my heart surging with passion."
  },
  {
    chinese: "古来圣贤皆寂寞，惟有饮者留其名。",
    english: "Since ancient times, sages have all been lonely, only drinkers leave their names behind."
  },
  {
    chinese: "少年易老学难成，一寸光阴不可轻。",
    english: "Youth easily fades while learning is hard to achieve, not an inch of time should be wasted."
  },
  {
    chinese: "碧水东流至此回，壮志未酬何时归。",
    english: "The blue waters flow east to here and turn, when will I return with my ambitions unfulfilled?"
  },
  {
    chinese: "一曲高歌破敌行，铁马金戈霜雪明。",
    english: "A high song of defeating the enemy, iron horses and golden spears shine in the frost and snow."
  },
  {
    chinese: "马蹄疾，剑气寒，一去千里斩将还。",
    english: "Swift horse hooves, cold sword spirit, going a thousand li to slay generals and return."
  },
  {
    chinese: "长风破浪会有时，直挂云帆济沧海。",
    english: "There will be a time when a strong wind breaks the waves, and we'll set our cloud-like sails straight to cross the vast sea."
  },
  {
    chinese: "十年磨一剑，霜刃未曾试。",
    english: "Ten years to grind a sword, its frosty blade not yet tested."
  },
  {
    chinese: "仗剑走天涯，游侠何须定居。",
    english: "Carrying a sword to roam the world, why should a wandering knight settle down?"
  },
  {
    chinese: "岁月不待人，青春恰自来。",
    english: "Time waits for no one, yet youth arrives on its own."
  },
  {
    chinese: "横笛卧听松风寒，古道边，断雁叫西风。",
    english: "Lying down to listen to the cold pine wind with a flute, by the ancient road, a lone wild goose calls in the west wind."
  },
  {
    chinese: "莫愁前路无知己，天下谁人不识君。",
    english: "Don't worry about having no confidant on the road ahead, who in the world doesn't know you?"
  },
  {
    chinese: "身无彩凤双飞翼，心有灵犀一点通。",
    english: "Though the body lacks the wings of a colorful phoenix, hearts have a touch of spiritual connection."
  },
  {
    chinese: "人生得意须尽欢，莫使金樽空对月。",
    english: "When life is at its peak, one must enjoy to the fullest, don't let the golden cup face the moon empty."
  },
  {
    chinese: "雁字回时，月满西楼，花自飘零水自流。",
    english: "When the wild geese return, the moon fills the western tower, flowers fall of their own accord and waters flow on their own."
  },
  {
    chinese: "问苍茫大地，谁主沉浮？",
    english: "Ask the vast earth, who controls the rise and fall?"
  },
  {
    chinese: "风雨不改凌云志，振衣濯足展襟怀。",
    english: "Wind and rain do not change the ambition to soar above the clouds, shaking off dust and washing feet, one opens up one's heart."
  },
  {
    chinese: "少年应有鸿鹄志，当骑骏马踏平川。",
    english: "The young should have lofty ambitions, riding fine horses to gallop across the plains."
  },
];

function Motto() {
  const [randomMotto, setRandomMotto] = useState(mottos[0]);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * mottos.length);
    setRandomMotto(mottos[randomIndex]);
  }, []);

  return (
    <div className="motto-container">
      <h6 className="motto-chinese">{randomMotto.chinese}</h6>
      <h6 className="motto-english">{randomMotto.english}</h6>
    </div>
  );
}

export default Motto;