import "./App.css";

import PersonData from "./components/PersonData";
import Champions from "./components/Champions";
import Motto from "./components/Motto";
import CoverPerson from "./components/CoverPerson";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="container-sm">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-8 col-lg-6">
              <CoverPerson />
              <PersonData />
              <Champions />
              <Motto />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
